const GOOGLE_FIREBASE_WEB_CLIENT_ID = "155167128714-0c45cp04ra5jheqcb1ruq7gar115rc7j.apps.googleusercontent.com"
 const GOOGLE_FIREBASE_WEB_API_KEY = "AIzaSyCFs4nB50RislFcWeNhYr3DrbUtjU0e3aU"

export const client_id = GOOGLE_FIREBASE_WEB_CLIENT_ID
export const apiKey = GOOGLE_FIREBASE_WEB_API_KEY
export const scope = 'https://www.googleapis.com/auth/calendar.events'
export const gmailScope = 'https://www.googleapis.com/auth/gmail.labels'
export const discoveryDocs = [
    'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
    'https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest',
    'https://docs.googleapis.com/$discovery/rest?version=v1',
    'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
    ,
]
